<template>
  <!-- 页面name：审核 -->
  <div class="contain">
    <div class="con-header">
      <span>详情</span>
    </div>
    <div class="base-info">
      <div class="con-header-little">
        <i class="el-icon-caret-bottom" style="margin-right:0.52083vw"></i>
        <!-- <img class="logo-img" src="../assets/img/jyjy-logo.png" alt="" /> -->
        <span>基本信息</span>
      </div>
      <div class="form">
        <el-row>
          <el-col :span="3">
            <div class="left-label">
              所属分公司:
            </div>
          </el-col>
          <el-col :span="21">
            <span class="con-fonts">
              {{ ruleForm.companyName }}
            </span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="3">
            <div class="left-label">
              患病园校:
            </div>
          </el-col>
          <el-col :span="21">
            <span class="con-fonts">
              {{ ruleForm.campusName }}
            </span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="3">
            <div class="left-label">
              患病班级:
            </div>
          </el-col>
          <el-col :span="21">
            <span class="con-fonts">
              {{ ruleForm.classLabel }}
            </span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="3">
            <div class="left-label">
              上报时间:
            </div>
          </el-col>
          <el-col :span="21">
            <span class="con-fonts">
              {{ ruleForm.reportTime }}
            </span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="3">
            <div class="left-label">
              传染病名称:
            </div>
          </el-col>
          <el-col :span="21">
            <span class="con-fonts" v-if="ruleForm.contagionName">
              {{ ruleForm.contagionName.label }}
            </span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="3">
            <div class="left-label">
              患病人数:
            </div>
          </el-col>
          <el-col :span="21">
            <span class="con-fonts">
              {{ ruleForm.sickNum }}
            </span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="3">
            <div class="left-label">
              严重程度:
            </div>
          </el-col>
          <el-col :span="21">
            <span class="con-fonts" v-if="ruleForm.level">
              {{ ruleForm.level.label }}
            </span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="3">
            <div class="left-label">
              上报人:
            </div>
          </el-col>
          <el-col :span="21">
            <span class="con-fonts">
              {{ ruleForm.reportName }}
            </span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="3">
            <div class="left-label">
              原因:
            </div>
          </el-col>
          <el-col :span="21">
            <span class="con-fonts">
              {{ ruleForm.reason }}
            </span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="3">
            <div class="left-label">
              采取措施:
            </div>
          </el-col>
          <el-col :span="21">
            <div class="measure-contain measure-mar">
              <div class="mea-con-tablehead tablehead-backg">
                <div class="tablehead-times tablehead-times-head">时间</div>
                <div class="tablehead-idea tablehead-times-head">采取措施</div>
              </div>
              <div>
                <div
                  v-if="ruleForm.historyList && ruleForm.historyList.length > 0"
                >
                  <div
                    class="mea-con-tablehead middle-content"
                    v-for="(item, index) in ruleForm.historyList"
                    :key="index"
                  >
                    <div class="tablehead-times con-fonts">
                      {{ item.measureTime }}
                    </div>
                    <div class="tablehead-idea con-fonts">
                      {{ item.measure }}
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="mea-con-tablehead middle-content">
                    <div class="tablehead-times con-fonts">
                      {{ ruleForm.measureTime }}
                    </div>
                    <div class="tablehead-idea con-fonts">
                      {{ ruleForm.measure }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="3">
            <div class="left-label">
              附件:
            </div>
          </el-col>
          <el-col :span="21">
            <div
              class="files-look"
              v-for="(item, index) in ruleForm.fileList"
              :key="index"
            >
              <img
                class="file-smallimg"
                src="../../../assets/img/attach.png"
                alt=""
              />
              <span class="con-fonts">
                {{ item.name }}
              </span>
              <span class="files-button">
                <el-button type="text">查看</el-button>
                <el-button type="text">下载</el-button>
              </span>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <!-- 意见反馈 -->
    <div class="feedback">
      <div class="con-header-little">
        <!-- <img class="logo-img" src="../assets/img/jyjy-logo.png" alt="" /> -->
        <i class="el-icon-caret-bottom" style="margin-right:0.52083vw"></i>
        <span>意见反馈</span>
      </div>
      <div class="form">
        <el-row>
          <el-col :span="24">
            <div class="handle-idea">
              <el-form
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                label-width="6.25vw"
                label-position="left"
                class="demo-ruleForm"
              >
                <el-form-item label="处理意见:" prop="measure">
                  <el-input
                    v-model="ruleForm.measure"
                    type="textarea"
                    class="elInput"
                    placeholder="请输入"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <div class="form-btn">
      <el-button class="goback" @click="onCommentStatus(1)">打回</el-button>
      <el-button class="cancel" @click="onCancel">取消</el-button>
      <el-button class="submit" @click="onCommentStatus(0)">通过</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lookId: "",
      ruleForm: {
        measure: "rd", //处理意见
        type: "2", //上报组织
      },
      rules: {
        companyLabel: [
          { required: true, message: "请选择公司", trigger: "blur" },
        ],
      },
    };
  },

  mounted() {
    console.log(this.$route.query.lookId, "分公司执行情况传递过来的参数");
    this.lookId = this.$route.query.lookId;
    this.tableLoading = true;
    this.init();
  },
  methods: {
    /** 初始化 */
    init() {
      let params = {
        id: this.lookId,
      };
      this.$api.getDiseaselookover(params).then((res) => {
        console.log(res, "查看ye");
        this.tableLoading = false;
        if (res.data.code == 0) {
          this.ruleForm = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //返回上一页
    goBack() {
      this.$router.go(-1);
    },

    //处理状态，打回还是通过
    //打回\通过状态
    onCommentStatus(comStatus) {
      console.log(comStatus, "tongguodahui");
      let params = {
        contagionId: this.lookId,
        status: comStatus,
        comment: this.ruleForm.measure,
      };
      this.$api.getCommentSave(params).then((res) => {
        console.log(res, "shifoudahui");
        if (res.data.code == 0) {
          // this.$message.success(res.data.msg);
          this.goBack();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //取消
    onCancel() {
      this.goBack();
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.container .layout-main-wrap .layout-main {
  background: #ffffff;
  height: 100%;
  box-sizing: border-box;
  overflow-x: scroll !important;
  overflow-y: scroll;
}
.contain {
  padding: 34px 30px 30px 30px;

  .con-header {
    font-size: 22px;
    font-weight: bold;
    line-height: 27px;
    color: #3d3e4f;
  }

  .con-header-little {
    margin: 40px 0 30px 0;
  }

  .con-header-little span {
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    color: #3d3e4f;
  }

  /deep/.el-form-item__label {
    line-height: 16px;
  }

  /deep/.el-form-item__content {
    width: 616px;
    display: flex;
    align-items: center;
    line-height: 16px;
    margin-left: 0 !important;
  }

  .base-info {
    width: 80%;
    /deep/ .el-form-item {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
    }
  }

  .left-label {
    font-size: 16px;
    font-weight: 400;
    color: #606266;
    margin-bottom: 30px;
  }

  .con-fonts {
    font-size: 14px;
    font-weight: 400;
    color: #010334;
  }

  .file-smallimg {
    width: 14px;
    height: 16px;
    margin-right: 8px;
  }

  .measure-mar {
    margin-bottom: 30px;
  }

  .measure-contain {
    width: 1200px;
    min-height: 104px;

    .middle-content {
      border-bottom: 2px solid #e6ebf3;
    }

    .mea-con-tablehead {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0;
      font-size: 14px;

      .tablehead-times {
        width: 200px;
        display: flex;
        justify-content: center;
      }
      .tablehead-idea {
        flex: 1;
        width: calc(100% - 240px);
        padding-left: 20px;
        overflow: hidden;
        word-break: break-all;
        display: flex;
      }
    }

    .tablehead-backg {
      height: 48px;
      background: #f1f4f9;
      border-radius: 12px 12px 0px 0px;
      padding: 0;
    }

    .tablehead-times-head {
      font-weight: 400;
      color: #5e6d9b;
    }
  }
  .files-look {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .files-button {
      margin-left: 40px;
    }
  }

  /deep/.el-button--text {
    padding: 0;
  }

  .handle-idea {
    /deep/.el-form-item__label {
      font-size: 16px;
    }
  }
  .feedback {
    width: 80%;
    /deep/.el-form-item {
      display: flex;
      margin-bottom: 30px;
    }

    /deep/.el-textarea__inner {
      height: 100px;
    }
  }

  .form-btn {
    margin-top: 43px;
    display: flex;
    padding-left: 100px;

    .goback {
      display: block;
      width: 137px;
      height: 40px;
      background: #ff4444;
      box-shadow: 0px 3px 10px rgba(255, 68, 68, 0.4);
      opacity: 1;
      border-radius: 4px;
      font-size: 14px;
      color: #ffffff;
    }

    .cancel {
      width: 137px;
      height: 40px;
      border: 1px solid #b5bdcf;
      border-radius: 4px;
      font-size: 14px;
      line-height: 14px;
      color: #222222;
      opacity: 1;
    }

    .submit {
      display: block;
      width: 137px;
      height: 40px;
      background: #003685;
      box-shadow: 0px 3px 10px rgba(52, 85, 236, 0.4);
      opacity: 1;
      border-radius: 4px;
      font-size: 14px;
      color: #ffffff;
    }

    /deep/.el-button + .el-button {
      margin-left: 20px;
    }
  }
}
</style>
